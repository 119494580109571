body {
  margin: 0;
  padding: 0;
  font-family: 'Red Hat Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #0074d9;
}
a:hover {
  color: #fc4c02;
}

#shell {
  position: relative;
}

#map {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.image {
  float: right;
  width: 75px;
  margin-top: 22px;
}

.container {
  cursor: default;
  position: fixed;
  z-index: 99;
  min-height: 290px;
  min-width: 384px;
  border-radius: 20px;
  padding: 20px 30px;
  bottom: 20px;
  right: 26px;
  color: #585858;
  background: #ffffffed;
  box-shadow: 3px 4px #cececeba;
}

.header {
  height: 80px;
  margin-bottom: 8px;
}

.avatar {
  float: left;
  width: 80px;
}

.title-box {
  font-size: 8px;
  text-transform: uppercase;
  margin-left: 100px;
  padding-top: 4px;
  font-kerning: none;
}

.title {
  font-weight: bold;
  margin-bottom: 2px;
  margin-top: 4px;
}

.sub-title {
  font-weight: normal;
  margin-top: 0px;
}

.image-logo {
  height: 14px;
  margin-right: 6px;
  border-radius: 2px;
}

.row {
  text-transform: uppercase;
  font-weight: bold;
  font-kerning: none;
  font-size: 16px;
  border-top: solid 1px #cecece;
  padding-bottom: 10px;
  padding-top: 16px;
}
.row > span {
  float: right;
  padding-left: 10px;
  font-size: 16px;
  text-transform: lowercase;
}
.row > b {
  font-size: 20px;
}

.commute-count {
  min-width: 34px;
  display: inline-block;
  font-size: 16px;
  text-align: center;
}

.footer {
  font-size: 10px;
  font-weight: normal;
  position: absolute;
  bottom: 26px;
}

.activity-key {
  float: left;
  border-radius: 50px;
  width: 12px;
  height: 12px;
  margin-top: 5px;
  margin-right: 6px;
  background: #fc4c02;
}

.commute-key {
  margin-top: 9px;
  background: #0f9222;
}

.state-key {
  float: left;
  width: 10px;
  height: 10px;
  margin-top: 3px;
  margin-right: 6px;
  background: #dbebed;
  border: 2px solid #0074d9;
}
